import { isTouchDevice } from '@ravespaceio/rave-engine/build/engine/src/utils/browser'


export default defineNuxtPlugin(nuxtApp => {

	// nuxtApp.provide('$isMobile', () => false) // throw error in terminal

	// https://stackoverflow.com/questions/63100658/add-global-variable-in-vue-js-3
	nuxtApp.vueApp.config.globalProperties.$isMobile = isTouchDevice()
	nuxtApp.vueApp.config.globalProperties.$isProd = process.env.NODE_ENV === 'production'
	nuxtApp.vueApp.config.globalProperties.$gamepadActive = false

	window.addEventListener("gamepadconnected", function (e) {
		nuxtApp.vueApp.config.globalProperties.$gamepadActive = true
	});
	window.addEventListener("gamepaddisconnected", function (e) {
		nuxtApp.vueApp.config.globalProperties.$gamepadActive = false
	});

})
